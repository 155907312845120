import styled from 'styled-components'

export const Title = styled.div`
    font-family: 'Italianno';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 150%;
    text-align: center;
`

export const Typography = styled.div`
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
`
